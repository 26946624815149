import Modal from '../../../components/shared/Modal';
import { BiX } from 'react-icons/bi';
import Divider from '../../../components/Divider';

import { conversationStore } from 'entities/tavus/conversation';

export const ViewFormDataModal = () => {
  const { setViewFormDataModal, viewFormDataModal } = conversationStore(
    (state) => ({
      setViewFormDataModal: state.setViewFormDataModal,
      viewFormDataModal: state.viewFormDataModal
    })
  );

  return (
    <Modal
      setOpen={() => setViewFormDataModal(null)}
      open={!!viewFormDataModal}
      closeButton={
        <BiX
          onClick={() => setViewFormDataModal(null)}
          className="ag-cursor-pointer ag-w-8 ag-h-8 ag-p-1.5 ag-absolute ag-right-4 ag-top-4"
        />
      }
      noPadding
      positionClasses="ag-inset-0 ag-overflow-auto ag-z-10 ag-p-4"
      className="!ag-w-[480px] max-sm:ag-mt-auto max-sm:ag-mb-4"
    >
      <div className="ag-text-left ag-p-6">
        <h2 className="ag-text-lg ag-font-semibold ag-text-neutral-900">
          View Form Data
        </h2>
        <Divider className="ag-border-neutral-200 ag-my-3" />

        <div className="ag-space-y-5">
          <div>
            <div className="ag-border ag-border-neutral-300 ag-rounded-md ag-p-3 ag-mb-2 ag-overflow-auto ag-max-h-96 ag-text-sm ag-space-y-2">
              {viewFormDataModal?.form_payload &&
                Object.keys(viewFormDataModal?.form_payload)?.map(
                  (key, index) => (
                    <div
                      key={index}
                      className={`my-1 rounded-lg whitespace-pre-wrap`}
                    >
                      <span className="ag-font-semibold">{key}:</span>{' '}
                      {viewFormDataModal?.form_payload[key]}
                    </div>
                  )
                )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
