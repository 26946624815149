import { ComponentType, FC, useState } from 'react';
import { useAuthStore } from 'stores/useAuthStore';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusPersona } from './TavusPersona';
import { clsx } from 'clsx';
import { cn } from 'shared/lib';
import { featureFlags } from 'shared/model';
interface IProps {
  ConversationsTableComponent: ComponentType<{}>;
}

export const TavusPersonasList: FC<IProps> = ({
  ConversationsTableComponent
}) => {
  const { tavusPersonas } = tavusPersonaStore((state) => ({
    tavusPersonas: state.tavusPersonas
  }));
  const isAuth = useAuthStore((state) => state.isAuth);
  const userPersonas = tavusPersonas?.filter((item) => item.user_id);
  const stockPersonas = tavusPersonas?.filter((item) => !item.user_id);

  const [activeTab, setActiveTab] = useState<'personas' | 'conversations'>(
    'personas'
  );

  return (
    <>
      {isAuth && (
        <div className="ag-flex ag-gap-4 ag-mb-6">
          <button
            className={clsx(
              'ag-px-4 ag-py-2 ag-rounded ag-font-medium',
              activeTab === 'personas'
                ? 'ag-bg-primary-700 ag-text-white'
                : 'ag-bg-gray-200 dark:ag-bg-gray-700'
            )}
            onClick={() => setActiveTab('personas')}
          >
            Personas
          </button>
          <button
            className={clsx(
              'ag-px-4 ag-py-2 ag-rounded ag-font-medium',
              activeTab === 'conversations'
                ? 'ag-bg-primary-700 ag-text-white'
                : 'ag-bg-gray-200 dark:ag-bg-gray-700'
            )}
            onClick={() => setActiveTab('conversations')}
          >
            Conversations
          </button>
        </div>
      )}

      {activeTab === 'personas' && (
        <>
          {isAuth && (
            <>
              <h2
                className={clsx(
                  'ag-font-semibold dark:ag-text-white',
                  userPersonas?.length ? 'ag-mb-4' : 'ag-mb-2'
                )}
              >
                My Video Agents
              </h2>
              <div
                className={clsx(
                  'ag-w-full ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6',
                  userPersonas?.length ? 'ag-grid' : ''
                )}
              >
                {userPersonas?.length ? (
                  userPersonas.map((persona, ind) => (
                    <TavusPersona key={persona.id + ind} persona={persona} />
                  ))
                ) : (
                  <div className={'ag-text-neutral-600 ag-w-full'}>
                    You don’t have any Video Agent yet.
                  </div>
                )}
              </div>
            </>
          )}
          {featureFlags.tStockAgents && (
            <>
              <h2
                className={cn(
                  'ag-font-semibold dark:ag-text-white',
                  stockPersonas.length ? 'ag-mb-4' : 'ag-mb-2'
                )}
              >
                Stock Video Agents
              </h2>
              <div
                className={cn(
                  'ag-w-full ag-grid-cols-2 md:ag-grid-cols-3 lg:ag-grid-cols-4 xl:ag-grid-cols-5 3xl:ag-grid-cols-6 5xl:ag-grid-cols-8 ag-gap-4 ag-flex-wrap ag-pb-6',
                  stockPersonas?.length ? 'ag-grid' : ''
                )}
              >
                {stockPersonas.length ? (
                  stockPersonas.map((persona, ind) => (
                    <TavusPersona key={persona.id + ind} persona={persona} />
                  ))
                ) : (
                  <div className={'ag-text-neutral-600 ag-w-full'}>
                    There are no stock video agents yet.
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}

      {activeTab === 'conversations' && isAuth && (
        <section>
          <ConversationsTableComponent />
        </section>
      )}
    </>
  );
};
