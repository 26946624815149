import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { MAIN_ROUTE, Loader, useQueryParams } from 'shared';
import { creditStore } from 'entities/credit';
import { userStore } from 'entities/user';
import { conversationStore } from 'entities/tavus/conversation/model/conversationStore';
import { tavusPersonaStore } from 'entities/tavus/persona';
import { TavusConference } from './TavusConference';

export const TavusCall = () => {
  const { startConversation, currentConversation, conversationLoading } =
    conversationStore();
  const { currentPersona } = tavusPersonaStore();
  const { user } = userStore();
  const { isChatAvailable } = creditStore();
  const nav = useNavigate();
  const params = useQueryParams();
  const language = params.get('language');
  useEffect(() => {
    if (user && isChatAvailable) {
      startConversation({
        currentPersona,
        key: undefined,
        language: language || 'english',
        preconversationId: params.get('preconversation_id')
      }).catch(() => {
        nav(MAIN_ROUTE);
      });
    }
  }, [user, isChatAvailable]);

  return (
    <>
      {conversationLoading ? (
        <div className="ag-w-full ag-h-screen ag-bg-neutral-900 ag-flex ag-items-center ag-justify-center   ">
          <Loader size={96} />
        </div>
      ) : (
        <>{currentConversation ? <TavusConference /> : null}</>
      )}
    </>
  );
};
