import { userStore } from 'entities/user';
import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { EIntegrationSettingsType } from 'entities/tavus/persona';

const generateCode = (
  personaId: string,
  internalKey: string | undefined,
  type: EIntegrationSettingsType
) => {
  const origin = window.location.origin;

  const url = `<iframe width='100%' height='100%' src='${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}&type=${type}' allow='microphone; camera'/>`;

  return url;
};

export const generateWidgetCode = ({
  personaId,
  internalKey,
  type,
  bubbleIconVariant,
  bubbleBackgroundColor,
  bubbleIconColor,
  darkMode,
  bubbleIconCustom,
  bubbleIconUrl,
  bubbleIconSide = 'right',
  customWidgetTrigger = ''
}: {
  personaId: string;
  internalKey: string | undefined;
  type: EIntegrationSettingsType;
  bubbleIconVariant: string;
  bubbleBackgroundColor: string;
  bubbleIconColor: string;
  darkMode: boolean;
  bubbleIconUrl?: string;
  bubbleIconCustom: boolean;
  bubbleIconSide?: 'left' | 'right';
  customWidgetTrigger?: string;
}) => {
  const origin = window.location.origin;

  const urls = [
    `<link rel='stylesheet' href='${origin}/widget/style.css'>
<script src='${origin}/widget/script.js'></script>`,
    `<script>
document.addEventListener('DOMContentLoaded', (event) => {
  initYepicChatWidget({ url: '${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}&type=${type}', bubbleIconVariant: '${bubbleIconVariant}', bubbleBackgroundColor: '${bubbleBackgroundColor}', bubbleIconColor: '${bubbleIconColor}', darkMode: ${darkMode}, bubbleIconUrl: '${bubbleIconUrl}', bubbleIconCustom: ${bubbleIconCustom}, bubbleIconSide: '${bubbleIconSide}', customWidgetTrigger: '${customWidgetTrigger}' });
});
</script>`,
    `${origin}/video-agents/avatars/event/${personaId}`,
    `${origin}/video-agents/avatars/event2`,
    `${origin}/video-agents/avatars/embed/${personaId}?key=${internalKey}`
  ];

  return urls;
};

export const useTavusGenerateEmbedCode = ({
  personaId,
  type,
  bubbleIconVariant = 'first',
  bubbleBackgroundColor = '#C34DFF',
  bubbleIconColor = '#FFFFFF',
  darkMode = false,
  bubbleIconUrl = '',
  bubbleIconCustom = false,
  bubbleIconSide = 'right',
  customWidgetTrigger = ''
}: {
  personaId: string;
  type: EIntegrationSettingsType;
  bubbleIconColor?: string;
  bubbleIconVariant?: string;
  bubbleBackgroundColor?: string;
  darkMode?: boolean;
  bubbleIconUrl?: string;
  bubbleIconCustom?: boolean;
  bubbleIconSide?: 'left' | 'right';
  customWidgetTrigger?: string;
}) => {
  const user = userStore((state) => state.user);
  const [embedCode, setEmbedCode] = useState(
    generateCode(personaId, user?.internalKey, type)
  );
  const [widgetCode, setWidgetCode] = useState(
    generateWidgetCode({
      personaId,
      internalKey: user?.internalKey,
      type,
      bubbleBackgroundColor,
      bubbleIconVariant,
      bubbleIconColor,
      darkMode,
      bubbleIconCustom,
      bubbleIconUrl,
      bubbleIconSide,
      customWidgetTrigger
    })
  );

  useEffect(() => {
    setEmbedCode(generateCode(personaId, user?.internalKey, type));
    setWidgetCode(
      generateWidgetCode({
        personaId,
        internalKey: user?.internalKey,
        type,
        bubbleBackgroundColor,
        bubbleIconColor,
        bubbleIconVariant,
        darkMode,
        bubbleIconCustom,
        bubbleIconUrl,
        bubbleIconSide,
        customWidgetTrigger
      })
    );
  }, [personaId, user, type]);

  const generateEmbedCode = () => {
    setEmbedCode(generateCode(personaId, user?.internalKey, type));
  };

  return {
    embedCode,
    generateEmbedCode,
    widgetCode
  };
};

export interface ISettingsFormik {
  videoAgentName: string;
  description: string;
  isFormEnabled: boolean;
  buttonText: string;
  poweredBy: boolean;

  mainColor: string;
  morePersonalisedColors: boolean;
  welcomeButtonColor?: string;
  darkMode: boolean;
  font: string;
  camera: boolean;
  languageSelection: boolean;
  captionsByDefault: boolean;

  customWidgetTrigger?: string;
  bubbleBackgroundColor?: string;
  bubbleIconColor?: string;
  bubbleIconUrl?: string;
  bubbleIconCustom?: boolean;
  bubbleIconSide?: 'left' | 'right';
  bubbleIconVariant?: string;

  domains?: string[];
}

export const validationSchema = Yup.object().shape({
  videoAgentName: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  buttonText: Yup.string().required('Required'),
  mainColor: Yup.string().matches(
    /^#([A-Fa-f0-9]{6})$/,
    'Invalid color format'
  ),
  welcomeButtonColor: Yup.string().matches(
    /^#([A-Fa-f0-9]{6})$/,
    'Invalid color format'
  ),
  bubbleBackgroundColor: Yup.string().matches(
    /^#([A-Fa-f0-9]{6})$/,
    'Invalid color format'
  ),
  bubbleIconColor: Yup.string().matches(
    /^#([A-Fa-f0-9]{6})$/,
    'Invalid color format'
  ),
  domain: Yup.string().url('Invalid URL format')
});
