import { HTMLProps, useEffect, useRef } from 'react';

export const Checkbox = ({
  className = '',
  ...props
}: HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  return (
    <div className="ag-flex ag-items-center ag-cursor-pointer">
      <input type="checkbox" ref={ref} className="hidden" {...props} />
      <svg
        className="ag-w-4 ag-h-4 ag-inline"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          fill="#FCF5FF"
          className={`${props.checked ? 'ag-visible' : 'ag-hidden'}`}
        />
        <path
          d="M12 5L6.5 10.5L4 8"
          stroke="#C34DFF"
          strokeWidth="1.6666"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`${props.checked ? 'ag-visible' : 'ag-hidden'}`}
        />
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="3.5"
          stroke="#D4D4D4"
          fill={`${props.checked ? 'none' : '#fff'}`}
        />
      </svg>
    </div>
  );
};
