import {
  EPreconversationType,
  IIntegrationSettings,
  IPreconversationConfig
} from 'entities/tavus/persona';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { cn, replaceUnderscoreAndCapitalize } from 'shared/lib';

interface IProps {
  formik: FormikProps<any>;
  preconversationConfig?: IPreconversationConfig;
  integrationSettings?: IIntegrationSettings;
}

export const TavusCallEmbedForm: FC<IProps> = ({
  formik,
  integrationSettings,
  preconversationConfig
}) => {
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="ag-space-y-2 ag-w-full ag-mb-2"
    >
      {preconversationConfig?.fields?.map((field) => (
        <div key={field.name} className="ag-flex ag-flex-col ag-gap-1.5">
          <label
            className={cn(
              'ag-text-sm ag-font-medium',
              integrationSettings?.dark_mode
                ? 'ag-text-white'
                : 'ag-text-neutral-700'
            )}
            htmlFor={`${field.name}`}
          >
            {field.label || replaceUnderscoreAndCapitalize(field.name)}
          </label>
          <input
            id={`${field.name}`}
            className={cn(
              'ag-border ag-p-2 ag-rounded-md',
              integrationSettings?.dark_mode && 'ag-bg-neutral-900',
              formik.errors.email && formik.touched.email
                ? 'ag-border-red-500'
                : integrationSettings?.dark_mode
                ? 'ag-border-neutral-600'
                : 'ag-border-gray-300'
            )}
            type={field.type === EPreconversationType.EMAIL ? 'email' : 'text'}
            name={field.name}
            value={formik?.values?.[field.name]}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
          />
          {formik.touched[field.name] && formik.errors[field.name] && (
            <span className="ag-text-red-500 ag-text-xs">
              {formik.errors[field.name] as unknown as string}
            </span>
          )}
        </div>
      ))}
    </form>
  );
};
