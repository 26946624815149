import { useParams } from 'react-router';
import { ConversationsTable } from 'widgets/conversations-table';

export const ConversationsPage = () => {
  const { personaId } = useParams();

  return (
    <div className="ag-p-10">
      <ConversationsTable personaId={personaId} />
    </div>
  );
};
