import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
  ColumnFiltersState,
  VisibilityState,
  RowSelectionState,
  getFacetedUniqueValues
} from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import { FilledButton } from '../Buttons';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import { cn, replaceUnderscoreAndCapitalize } from 'shared/lib';
import { BiChevronDown } from 'react-icons/bi';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';
import { Filter } from './Filter';
import { GoColumns } from 'react-icons/go';
import { Checkbox } from '../Checkbox';

interface DataTableProps<TData> {
  data: TData[];
  columns: ColumnDef<TData, unknown>[];
  rowSelection?: RowSelectionState;
  enableRowSelection?: boolean;
  enablePagination?: boolean;
  getRowClassName?: (row: TData) => string;
  onChangeSelection?: (selectedRows: TData[]) => void;
  clientFiltering?: boolean;
}

const pageSizeOptions = [5, 10, 20, 50, 100];

export function DataTable<TData>({
  data,
  columns,
  getRowClassName,
  enablePagination = false,
  enableRowSelection,
  onChangeSelection,
  clientFiltering
}: DataTableProps<TData>) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sortingState, setSortingState] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSizeOptions[0]
  });

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    Object.fromEntries(
      columns
        .map((col) => [
          (col as { accessorKey?: string }).accessorKey,
          !col.meta?.hiddenByDefault
        ])
        .filter(([key]) => key !== undefined)
    )
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: clientFiltering ? getFilteredRowModel() : undefined,
    getFacetedUniqueValues: clientFiltering
      ? getFacetedUniqueValues()
      : undefined,
    onSortingChange: setSortingState,
    onRowSelectionChange: enableRowSelection ? setRowSelection : undefined,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    enableRowSelection,
    state: {
      rowSelection: enableRowSelection ? rowSelection : undefined,
      sorting: sortingState,
      columnFilters: clientFiltering ? columnFilters : undefined,
      columnVisibility,
      pagination
    },
    getPaginationRowModel: enablePagination
      ? getPaginationRowModel()
      : undefined
  });

  useEffect(() => {
    if (onChangeSelection) {
      const selectedRows = table
        .getSelectedRowModel()
        .rows?.map((row) => row.original);
      onChangeSelection(selectedRows);
    }
  }, [rowSelection, table, onChangeSelection]);

  return (
    <div className="ag-w-full">
      <div className="ag-mb-4 ag-flex">
        <Listbox
          value={Object.keys(columnVisibility).filter(
            (col) => columnVisibility[col]
          )}
          onChange={(selectedColumns) => {
            setColumnVisibility((prev) => ({
              ...prev,
              ...Object.fromEntries(
                Object.keys(columnVisibility).map((col) => [
                  col,
                  selectedColumns.includes(col)
                ])
              )
            }));
          }}
          multiple
        >
          <ListboxButton className="ag-flex ag-items-center ag-gap-2 ag-border ag-border-gray-300 ag-p-2 ag-rounded">
            <GoColumns />
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            className="ag-absolute ag-mt-1 ag-bg-white ag-border ag-shadow-lg ag-rounded ag-z-10"
          >
            {Object.keys(columnVisibility).map((col) => (
              <ListboxOption
                key={col}
                value={col}
                className="ag-cursor-pointer ag-px-4 ag-py-2 hover:ag-bg-gray-200"
              >
                <div className="ag-flex ag-items-center ag-gap-2 ag-select-none">
                  <Checkbox
                    checked={columnVisibility[col]}
                    readOnly
                    onChange={() => {}}
                  />
                  {replaceUnderscoreAndCapitalize(col)}
                </div>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Listbox>
      </div>
      <div className="w-full overflow-auto ag-h-[34.5rem] border border-gray-300">
        <table className="min-w-full">
          <thead className="bg-gray-100 sticky top-0 z-10 ">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 text-left cursor-pointer select-none"
                  >
                    {header.column.getCanFilter() && clientFiltering ? (
                      <div className="relative inline-block">
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                    <span onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </span>

                    {header.column.getIsSorted() === 'asc'
                      ? ' ▲'
                      : header.column.getIsSorted() === 'desc'
                      ? ' ▼'
                      : ''}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr
                  className={cn(
                    `border-b`,
                    getRowClassName ? getRowClassName(row.original) : ''
                  )}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      style={{ width: cell.column.getSize() + 'px' }}
                      key={cell.id}
                      className="px-4 py-2"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {enablePagination && (
        <>
          {enableRowSelection && (
            <div className="ag-mt-4 ag-h-5 ag-flex ag-items-center ag-gap-4">
              <div className="ag-flex ag-items-center ag-gap-2">
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler()
                  }}
                />
                <div>Page Rows ({table.getRowModel().rows.length})</div>
              </div>
              |<div>Selected: {table.getSelectedRowModel().rows?.length}</div>
            </div>
          )}

          <div className="ag-flex ag-flex-col ag-gap-3 sm:ag-flex-row ag-justify-between ag-items-center">
            <div className="ag-mb-2 sm:ag-mb-0 ag-flex ag-gap-3">
              <span>Total: {data.length}</span>
            </div>
            <div className="ag-flex ag-items-center ag-gap-4">
              <span>Rows per page:</span>
              <Listbox
                value={pagination.pageSize}
                onChange={(newSize) => {
                  setPagination({
                    pageSize: newSize,
                    pageIndex: 0
                  });
                }}
              >
                <div className="ag-relative">
                  <ListboxButton className="ag-flex ag-items-center ag-gap-2 ag-border ag-border-gray-300 ag-p-1 ag-rounded">
                    {pagination.pageSize}
                    <BiChevronDown />
                  </ListboxButton>
                  <ListboxOptions
                    anchor="top end"
                    className="ag-absolute ag-mt-1 ag-bg-white ag-border ag-shadow-lg ag-rounded"
                  >
                    {pageSizeOptions.map((size) => (
                      <ListboxOption
                        key={size}
                        value={size}
                        className="ag-cursor-pointer ag-px-4 ag-py-2 hover:ag-bg-gray-200"
                      >
                        {size}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </Listbox>
            </div>
            <div className="ag-flex ag-items-center ag-gap-2">
              <FilledButton
                className="ag-px-4 ag-py-2"
                onClick={() => {
                  table.previousPage();
                }}
                disabled={!table.getCanPreviousPage()}
              >
                Back
              </FilledButton>
              <span>
                Page {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </span>
              <FilledButton
                className="ag-px-4 ag-py-2"
                onClick={() => {
                  table.nextPage();
                }}
                disabled={!table.getCanNextPage()}
              >
                Next
              </FilledButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
