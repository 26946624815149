import { FC, useEffect, useState } from 'react';
import { cn } from 'shared/lib';

interface IProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Switch: FC<IProps> = ({ checked = false, onChange }) => {
  const [isOn, setIsOn] = useState(checked);

  useEffect(() => {
    setIsOn(checked);
  }, [checked]);

  const handleToggle = () => {
    onChange?.(!isOn);
  };

  return (
    <button
      type="button"
      onClick={handleToggle}
      className={cn(
        'ag-w-11 ag-h-6 ag-flex ag-items-center ag-rounded-full ag-p-1 ag-transition-colors ag-duration-300',
        isOn ? 'ag-bg-primary-600' : 'ag-bg-gray-300'
      )}
    >
      <div
        className={cn(
          'ag-bg-white ag-w-5 ag-h-5 ag-rounded-full ag-shadow-md ag-transform ag-transition-transform ag-duration-300',
          isOn ? 'ag-translate-x-4' : 'ag-translate-x-0'
        )}
      />
    </button>
  );
};
