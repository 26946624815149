import {
  bubbleIcons,
  ISettingsFormik,
  useTavusGenerateEmbedCode
} from '../../model';
import {
  EIntegrationSettingsType,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { CreditsWarning, FilledButton } from 'shared/ui';
import Divider from 'components/Divider';
import { showSuccessMessage } from 'shared/model';
import { activeClasses, cn } from 'shared/lib';
import { FC } from 'react';
import { FormikProps } from 'formik';

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const Integrate: FC<IProps> = ({ formik }) => {
  const { personaEmbedModal } = tavusPersonaStore();
  const bubbleIcon =
    Object.entries(bubbleIcons).find(([key, value]) => {
      if (value.variant === formik.values.bubbleIconVariant) return true;
      return false;
    }) || 'first';
  const { embedCode, widgetCode } = useTavusGenerateEmbedCode({
    personaId: personaEmbedModal?.personaId as string,
    type: personaEmbedModal?.type!,
    bubbleBackgroundColor: formik.values.bubbleBackgroundColor!,
    bubbleIconColor: formik.values.bubbleIconColor!,
    bubbleIconCustom: formik.values.bubbleIconCustom,
    bubbleIconUrl: formik.values.bubbleIconUrl,
    bubbleIconVariant: bubbleIcon[0],
    darkMode: formik.values.darkMode,
    bubbleIconSide: formik.values.bubbleIconSide,
    customWidgetTrigger: formik.values.customWidgetTrigger
  });

  return (
    <>
      <CreditsWarning />
      <div>
        <div className="ag-mb-2 ag-text-neutral-700 ag-font-medium">
          {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET
            ? 'How to install the widget'
            : 'How to embed the Video Agent'}
        </div>
        <Divider />
      </div>
      {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET ? (
        <>
          <div>
            <div className="ag-text-sm ag-mb-1.5">
              Paste this code inside a ‘head’ tag
            </div>
            <div className="ag-border ag-border-neutral-300 ag-rounded-md ag-p-3 ag-mb-1.5 ag-overflow-auto ag-max-h-32">
              {widgetCode[0]}
            </div>
            <FilledButton
              className={cn(
                'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm ag-mb-6 hover:ag-bg-primary-700',
                activeClasses
              )}
              onClick={() =>
                navigator.clipboard
                  .writeText(widgetCode[0])
                  .then(() =>
                    showSuccessMessage(
                      'The head part of widget code has been copied'
                    )
                  )
              }
            >
              Copy
            </FilledButton>
          </div>
          <div>
            <div className="ag-text-sm ag-mb-1.5">
              Paste this code before bod tag
            </div>
            <div className="ag-border ag-border-neutral-300 ag-rounded-md ag-p-3 ag-mb-1.5 ag-overflow-auto ag-max-h-32">
              {widgetCode[1]}
            </div>
            <FilledButton
              className={cn(
                'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm  hover:ag-bg-primary-700',
                activeClasses
              )}
              onClick={() =>
                navigator.clipboard
                  .writeText(widgetCode[1])
                  .then(() =>
                    showSuccessMessage(
                      'The head part of widget code has been copied'
                    )
                  )
              }
            >
              Copy
            </FilledButton>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="ag-text-sm ag-mb-1.5">Paste this code</div>
            <div className="ag-border ag-border-neutral-300 ag-rounded-md ag-p-3 ag-mb-1.5 ag-overflow-auto ag-max-h-32">
              {embedCode}
            </div>
            <FilledButton
              className={cn(
                'ag-py-1.5 ag-px-2 ag-font-semibold ag-leading-6 ag-border ag-border-secondary disabled:ag-bg-primary-200 disabled:ag-border-primary-200 ag-text-sm ag-mb-6 hover:ag-bg-primary-700',
                activeClasses
              )}
              onClick={() =>
                navigator.clipboard
                  .writeText(embedCode)
                  .then(() =>
                    showSuccessMessage('The embed code has been copied')
                  )
              }
            >
              Copy
            </FilledButton>
          </div>
        </>
      )}
    </>
  );
};
