export const CUSTOM_TALKING_PHOTO_TUTORIAL_VIDEO_ID = 'ozpLvZEdVsA';

export const DEFAULT_ENGINE = 'gpt-4o';

export const PERSONA_DEFINITION_CHAR_LIMIT = 100000;

export const STT_LOCALES = [
  'de-DE',
  'en-US',
  'ar-AE',
  'es-ES',
  'zh-CN',
  'ru-RU'
] as const;

export const STT_LOCALES_NAMES = [
  'German',
  'English',
  'Arabic',
  'Spanish',
  'Chinese',
  'Russian'
] as const;

export const LANGUAGES = [
  'German',
  'English',
  'Arabic',
  'Spanish',
  'Chinese',
  'Russian',
  'Hindi',
  'Portuguese',
  'French',
  'Japanese',
  'Korean',
  'Indonesian',
  'Italian',
  'Dutch',
  'Turkish',
  'Polish',
  'Swedish',
  'Norwegian',
  // 'Filipino',
  'Malay',
  'Romanian',
  'Hungarian',
  'Ukrainian',
  'Greek',
  'Czech',
  'Danish',
  'Finnish',
  'Bulgarian',
  'Croatian',
  'Slovak',
  'Tamil',
  'Vietnamese'
];

export const USER_CHOICES = {
  videoEnabled: false,
  audioEnabled: true,
  audioDeviceId: '',
  videoDeviceId: ''
};

export const AGENTS_NAVIGATION_EVENT = 'agents navigated'; // Update these in studio if changing
export const STUDIO_NAVIGATION_EVENT = 'studio navigated'; // Update these in studio if changing
