import Divider from 'components/Divider';
import { ISettingsFormik } from '../../model';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { Field, Switch } from 'shared/ui';
import { tavusPersonaStore } from 'entities/tavus/persona';

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const GeneralSettings: FC<IProps> = ({ formik }) => {
  const { tavusPersonas, personaEmbedModal } = tavusPersonaStore((state) => ({
    tavusPersonas: state.tavusPersonas,
    personaEmbedModal: state.personaEmbedModal
  }));

  const currentPersona = tavusPersonas.find(
    (persona) => persona.id === personaEmbedModal?.personaId
  );

  return (
    <>
      <Field label="Video Agent Name" name="videoAgentName" formik={formik} />
      <Field label="Description" name="description" formik={formik} />
      <Field label="Button" name="buttonText" formik={formik} />
      <Divider />
      <div>
        <div className="ag-flex ag-items-center ag-justify-between">
          <span className="ag-text-neutral-700 ag-font-medium">
            Form Enabled
          </span>
          <Switch
            onChange={(value) => {
              if (
                currentPersona?.preconversation_config?.fields?.length &&
                value
              ) {
                formik.setFieldValue('isFormEnabled', value);
              } else {
                formik.setFieldValue('isFormEnabled', false);
              }
            }}
            checked={formik.values.isFormEnabled}
          />
        </div>
        {!currentPersona?.preconversation_config?.fields?.length && (
          <div className="ag-text-xs ag-text-neutral-300">
            You don&apos;t have a form to fill out to enable this switch.
          </div>
        )}
      </div>

      <div className="ag-flex ag-items-center ag-justify-between">
        <span className="ag-text-neutral-700 ag-font-medium">
          Powered by Yepic AI
        </span>
        <Switch
          onChange={(value) => formik.setFieldValue('poweredBy', value)}
          checked={formik.values.poweredBy}
        />
      </div>
    </>
  );
};
