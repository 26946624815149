import { FormikProps } from 'formik';
import { bubbleIcons, ISettingsFormik } from '../../model';
import { FC, useRef } from 'react';
import { Field, RadioButton, Switch } from 'shared/ui';
import Divider from 'components/Divider';
import { HexColorInput } from 'react-colorful';
import ColorSelect from 'shared/ui/ColorSelect/ColorSelect';
import { cn, getErrorMessage, truncateText } from 'shared/lib';
import SelectInput from 'components/shared/SelectInput';
import {
  EIntegrationSettingsType,
  tavusPersonaStore
} from 'entities/tavus/persona';
import { LuUploadCloud } from 'react-icons/lu';
import { useUploadFile } from 'components/SideBar/Avatars/CustomTalkingPhotoFlow/hooks/useUploadFile';
import { showError } from 'shared/model';
import { BiCross, BiX } from 'react-icons/bi';

interface IProps {
  formik: FormikProps<ISettingsFormik>;
}
export const InterfaceSettings: FC<IProps> = ({ formik }) => {
  const { personaEmbedModal } = tavusPersonaStore((state) => ({
    personaEmbedModal: state.personaEmbedModal
  }));

  const uploadref = useRef<HTMLInputElement | null>(null);

  const { isLoading, error, uploadFile } = useUploadFile();

  const handleUpload = async (files: FileList) => {
    try {
      const data = await uploadFile(files[0]);
      if (!data) return;
      if (data.mediaLink) {
        formik.setFieldValue('bubbleIconUrl', data.mediaLink);
        formik.setFieldValue('bubbleIconCustom', true);
      }
    } catch (error) {
      const message = getErrorMessage(
        error,
        'There was a problem when we tried to upload file. Please try again later.'
      );
      showError(message);
    }
  };

  return (
    <>
      <div className="ag-flex ag-justify-between">
        <label
          className="ag-cursor-pointer ag-flex ag-items-center ag-gap-2"
          htmlFor="main-color"
        >
          {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET && (
            <RadioButton
              id="main-color"
              name="mainColor"
              value="one-color"
              checked={!formik.values.morePersonalisedColors}
              onChange={() =>
                formik.setFieldValue('morePersonalisedColors', false)
              }
            />
          )}

          <div className="ag-text-neutral-700 ag-font-medium">Main color</div>
        </label>
        <div className="ag-flex ag-gap-1">
          <HexColorInput
            prefixed
            color={formik.values.mainColor}
            onChange={(color) => formik.setFieldValue('mainColor', color)}
            className="ag-h-8 ag-max-w-24 ag-rounded-lg ag-border ag-border-neutral-200 ag-py-1.5 ag-px-3"
          />
          <ColorSelect
            pickerClassName="-ag-ml-48"
            className="ag-w-8 ag-h-8 ag-rounded-lg ag-border ag-border-neutral-200"
            color={formik.values.mainColor}
            onChange={(color) => {
              formik.setFieldValue('mainColor', color);
            }}
          />
        </div>
      </div>
      {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET && (
        <div>
          <label
            className="ag-flex ag-cursor-pointer"
            htmlFor="personalised-color"
          >
            <div className="ag-flex ag-gap-2">
              <RadioButton
                id="personalised-color"
                name="mainColor"
                value="personalised"
                checked={formik.values.morePersonalisedColors}
                onChange={() =>
                  formik.setFieldValue('morePersonalisedColors', true)
                }
              />
              <div className="ag-text-neutral-700 ag-font-medium">
                More personalised colours
              </div>
            </div>
          </label>
          {formik.values.morePersonalisedColors && (
            <div className="ag-pl-8 ag-pt-4 ag-space-y-4">
              <div className="ag-text-neutral-600 ag-flex ag-justify-between ag-items-center">
                <div>Welcome Button</div>
                <div className="ag-flex ag-gap-1">
                  <HexColorInput
                    prefixed
                    color={formik.values.welcomeButtonColor}
                    onChange={(color) =>
                      formik.setFieldValue('welcomeButtonColor', color)
                    }
                    className="ag-h-8 ag-max-w-24 ag-rounded-lg ag-border ag-border-neutral-200 ag-py-1.5 ag-px-3"
                  />
                  <ColorSelect
                    pickerClassName="-ag-ml-48"
                    className="ag-w-8 ag-h-8 ag-rounded-lg ag-border ag-border-neutral-200"
                    color={formik.values.welcomeButtonColor}
                    onChange={(color) => {
                      formik.setFieldValue('welcomeButtonColor', color);
                    }}
                  />
                </div>
              </div>
              {!formik.values.bubbleIconCustom && (
                <>
                  <div className="ag-text-neutral-600 ag-flex ag-justify-between ag-items-center">
                    <div>Bubble background</div>
                    <div className="ag-flex ag-gap-1">
                      <HexColorInput
                        prefixed
                        className="ag-h-8 ag-max-w-24 ag-rounded-lg ag-border ag-border-neutral-200 ag-py-1.5 ag-px-3"
                        color={formik.values.bubbleBackgroundColor}
                        onChange={(color) => {
                          formik.setFieldValue('bubbleBackgroundColor', color);
                        }}
                      />
                      <ColorSelect
                        pickerClassName="-ag-ml-48"
                        className="ag-w-8 ag-h-8 ag-rounded-lg ag-border ag-border-neutral-200"
                        color={formik.values.bubbleBackgroundColor}
                        onChange={(color) => {
                          formik.setFieldValue('bubbleBackgroundColor', color);
                        }}
                      />
                    </div>
                  </div>
                  <div className="ag-text-neutral-600 ag-flex ag-justify-between ag-items-center">
                    <div>Bubble icon</div>
                    <div className="ag-flex ag-gap-1">
                      <HexColorInput
                        prefixed
                        className="ag-h-8 ag-max-w-24 ag-rounded-lg ag-border ag-border-neutral-200 ag-py-1.5 ag-px-3"
                        color={formik.values.bubbleIconColor}
                        onChange={(color) => {
                          formik.setFieldValue('bubbleIconColor', color);
                        }}
                      />
                      <ColorSelect
                        pickerClassName="-ag-ml-48"
                        className="ag-w-8 ag-h-8 ag-rounded-lg ag-border ag-border-neutral-200"
                        color={formik.values.bubbleIconColor}
                        onChange={(color) => {
                          formik.setFieldValue('bubbleIconColor', color);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      <Divider />
      <div className="ag-flex ag-items-center ag-justify-between">
        <span className="ag-text-neutral-700 ag-font-medium">Dark Mode</span>
        <Switch
          onChange={(value) => formik.setFieldValue('darkMode', value)}
          checked={formik.values.darkMode}
        />
      </div>
      <Divider />
      <div className="ag-flex ag-items-center ag-justify-between">
        <span className="ag-text-neutral-700 ag-font-medium">Font</span>
        <SelectInput
          name="select-language"
          options={[
            'Roboto',
            'Open Sans',
            'Lato',
            'Montserrat',
            'Poppins',
            'Raleway',
            'Oswald',
            'Merriweather',
            'Playfair Display',
            'Inter'
          ]}
          value={truncateText(formik.values.font, 7)}
          onChange={(value) => formik.setFieldValue('font', value)}
          optionsWrapperClassName="!ag-bg-white !ag-border-neutral-200 ag-left-auto ag-right-0"
          className="ag-h-8 !ag-bg-white !ag-border-neutral-200 !ag-text-neutral-700"
          wrapperClassName="!ag-bg-white !ag-text-neutral-700 !ag-border-neutral-200 !ag-w-28"
          optionsClassName="ag-text-neutral-700 ag-border-neutral-200 ag-text-sm ag-py-1 hover:ag-bg-neutral-200/80 dark:hover:ag-bg-[#262626]/80"
        />
      </div>
      <Divider />
      <div className="ag-flex ag-items-center ag-justify-between">
        <span className="ag-text-neutral-700 ag-font-medium">Camera</span>
        <Switch
          onChange={(value) => formik.setFieldValue('camera', value)}
          checked={formik.values.poweredBy}
        />
      </div>
      <div className="ag-flex ag-items-center ag-justify-between ag-flex-wrap">
        <span className="ag-text-neutral-700 ag-font-medium">
          Language selection
        </span>
        <Switch
          onChange={(value) => formik.setFieldValue('languageSelection', value)}
          checked={formik.values.languageSelection}
        />
      </div>
      <div>
        <div className="ag-flex ag-items-center ag-justify-between ag-flex-wrap">
          <span className="ag-text-neutral-700 ag-font-medium">
            Captions by default
          </span>
          <Switch
            onChange={(value) =>
              formik.setFieldValue('captionsByDefault', value)
            }
            checked={formik.values.captionsByDefault}
          />
        </div>
        <div className="ag-text-neutral-600 ag-text-sm">
          If disabled, users need to enable them from the menu
        </div>
      </div>
      {personaEmbedModal?.type === EIntegrationSettingsType.WIDGET && (
        <>
          <Divider />
          <div className="ag-flex ag-items-center ag-justify-between">
            <div className="ag-text-neutral-700 ag-font-medium">
              <div>Bubble Icon Side</div>
              <div className="ag-text-neutral-600 ag-text-sm">
                {formik.values.bubbleIconSide === 'right'
                  ? 'Right side'
                  : 'Left side'}
              </div>
            </div>
            <Switch
              onChange={(value) =>
                formik.setFieldValue('bubbleIconSide', value ? 'right' : 'left')
              }
              checked={formik.values.bubbleIconSide === 'right'}
            />
          </div>
          <Divider />
          <Field
            label="Custom Widget Trigger(Selector)"
            name="customWidgetTrigger"
            formik={formik}
          />
          <Divider />
          <div>
            <div className="ag-text-neutral-700 ag-font-medium ag-mb-2">
              Minimised chat (bubble)
            </div>
            <div className="ag-flex ag-items-center ag-gap-2 ag-cursor-pointer">
              {formik.values.bubbleIconUrl ? (
                <div
                  onClick={() => {
                    formik.setFieldValue('bubbleIconCustom', true);
                  }}
                  className={cn(
                    'ag-py-4 ag-px-6 ag-border ag-rounded-lg ag-relative',
                    formik.values.bubbleIconCustom
                      ? 'ag-border-primary-600'
                      : 'ag-border-neutral-200'
                  )}
                >
                  <div
                    onClick={() => {
                      formik.setFieldValue('bubbleIconCustom', false);
                      formik.setFieldValue('bubbleIconUrl', '');
                    }}
                    className="ag-w-4 ag-h-4 ag-flex ag-items-center ag-justify-center ag-border ag-cursor-pointer ag-border-neutral-600 ag-absolute -ag-right-1 -ag-top-1 ag-text-neutral-600 ag-bg-white ag-rounded-full"
                  >
                    <BiX size={12} />
                  </div>
                  <img
                    className="ag-w-10 ag-h-10 ag-object-cover"
                    src={formik.values.bubbleIconUrl}
                  />
                </div>
              ) : (
                <label
                  className={cn(
                    'ag-py-4 ag-px-6 ag-text-primary-600 ag-cursor-pointer'
                  )}
                  htmlFor="upload-bubble-icon"
                >
                  <LuUploadCloud size={20} />
                  <input
                    ref={uploadref}
                    accept="image/png, image/jpeg, image/jpg"
                    className="hidden"
                    type="file"
                    id={'upload-bubble-icon'}
                    name={'upload-bubble-icon'}
                    onChange={(e) =>
                      e.target.files && handleUpload(e.target.files)
                    }
                  />
                </label>
              )}

              {Object.values(bubbleIcons).map((icon) => (
                <div
                  onClick={() => {
                    formik.setFieldValue('bubbleIconCustom', false);
                    formik.setFieldValue('bubbleIconVariant', icon.variant);
                  }}
                  key={icon.variant}
                  className={cn(
                    'ag-py-4 ag-px-6 ag-border ag-rounded-lg',
                    formik.values.bubbleIconVariant === icon.variant &&
                      !formik.values.bubbleIconCustom
                      ? 'ag-border-primary-600'
                      : 'ag-border-neutral-200'
                  )}
                >
                  {icon.draw()}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};
