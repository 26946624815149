import { FC, memo, useEffect, useMemo, useState } from 'react';
import {
  conversationStore,
  IConversationItem,
  processJSONTranscriptionsToText
} from 'entities/tavus/conversation';
import { ITavusPersona, tavusPersonaStore } from 'entities/tavus/persona';
import { DataTable, Input, Loader } from 'shared/ui';
import {
  calculateDuration,
  cn,
  exportToCSV,
  formatDate,
  scrollBarClasses
} from 'shared/lib';
import { getConversationsColumns } from './conversations-columns';
import { fullNameSelector, userStore } from 'entities/user';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from '@headlessui/react';
import { VscChevronDown } from 'react-icons/vsc';

interface IProps {
  personaId?: string | undefined;
  fullScreen?: boolean;
}

export const ConversationsTable: FC<IProps> = memo(({ personaId }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [currentConversationLoading, setCurrentConversationLoading] = useState<
    string | null
  >(null);
  const fullName = userStore(fullNameSelector);

  const {
    conversationsList,
    conversationsListLoading,
    fetchAll,
    transcriptionsLoading,
    getTranscriptions,
    setViewTranscriptionsModal,
    setViewFormDataModal
  } = conversationStore((state) => ({
    conversationsList: state.conversationsList,
    conversationsListLoading: state.conversationsListLoading,
    fetchAll: state.fetchAll,
    transcriptionsLoading: state.transcriptionsLoading,
    getTranscriptions: state.getTranscriptions,
    setViewTranscriptionsModal: state.setViewTranscriptionsModal,
    setViewFormDataModal: state.setViewFormDataModal
  }));

  const {
    fetchOne,
    currentPersona,
    setCurrentPersona,
    fetchPersonas,
    personas
  } = tavusPersonaStore((state) => ({
    fetchOne: state.fetchOne,
    currentPersona: state.currentPersona,
    setCurrentPersona: state.setCurrentPersona,
    fetchPersonas: state.fetchPersonas,
    personas: state.tavusPersonas
  }));

  const [selectedPersona, setSelectedPersona] = useState<ITavusPersona | null>(
    currentPersona
  );
  const filteredPersonas = personas?.filter((persona) =>
    persona.persona_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOpenTranscriptionsModal = (conversation: IConversationItem) => {
    setViewTranscriptionsModal(conversation);
  };

  const handleOpenFormDataModal = (conversation: IConversationItem) => {
    setViewFormDataModal(conversation);
  };

  useEffect(() => {
    if (personas.length === 0) {
      fetchPersonas();
    }
  }, []);

  useEffect(() => {
    if (currentPersona) {
      setSelectedPersona(currentPersona);
    }
  }, [currentPersona]);

  useEffect(() => {
    if (personaId && personaId !== selectedPersona?.id) {
      fetchOne(personaId).then((persona) => {
        if (persona) setCurrentPersona(persona);
      });
      fetchAll(personaId);
    }
  }, [personaId, fetchOne, setCurrentPersona, fetchAll]);

  useEffect(() => {
    if (selectedPersona && selectedPersona.id !== currentPersona?.id) {
      fetchOne(selectedPersona.id).then((persona) => {
        if (persona) setCurrentPersona(persona);
      });
      fetchAll(selectedPersona.id);
    }
  }, [selectedPersona, fetchOne]);

  useEffect(() => {
    if (!transcriptionsLoading) setCurrentConversationLoading(null);
  }, [transcriptionsLoading]);

  const [rowSelection, setRowSelection] = useState<IConversationItem[]>([]);

  const exportConversations = () => {
    exportToCSV(
      rowSelection.map((item) => ({
        persona_name: currentPersona?.persona_name,
        status: item.status,
        created_at: formatDate(item.created_at),
        updated_at: formatDate(item.updated_at),
        json_transcriptions: item.external_transcripts
          ? JSON.stringify(item.external_transcripts)
          : 'N/A',
        text_transcriptions: item.external_transcripts
          ? processJSONTranscriptionsToText({
              messages: item.external_transcripts,
              userName: fullName,
              assistantName: currentPersona?.persona_name
            })
          : 'N/A',
        form_data: item.form_payload
          ? Object.keys(item.form_payload)
              .map((key) => `${key}: ${item.form_payload[key]}`)
              .join('\n')
          : 'N/A',
        duration:
          item.created_at && item.updated_at
            ? `${calculateDuration(item.created_at, item.updated_at)} sec`
            : 'N/A'
      })),
      'conversations'
    );
  };

  const columns = useMemo(
    () =>
      getConversationsColumns({
        currentConversationLoading,
        handleOpenFormDataModal,
        setCurrentConversationLoading,
        getTranscriptions,
        handleOpenTranscriptionsModal,
        exportConversations,
        userName: fullName,
        assistantName: currentPersona?.persona_name
      }),
    [
      currentConversationLoading,
      setCurrentConversationLoading,
      getTranscriptions,
      fullName,
      currentPersona,
      handleOpenTranscriptionsModal,
      exportConversations
    ]
  );

  if (personaId && (!currentPersona || !selectedPersona)) {
    return (
      <div
        className={cn(
          'ag-w-full ag-flex ag-items-center ag-justify-center -ag-pt-24 ag-h-screen'
        )}
      >
        <Loader size={96} />
      </div>
    );
  }

  return (
    <div>
      <div className="ag-mb-4 ag-flex ag-items-center">
        <label htmlFor="filter" className="ag-mr-2 ag-font-medium">
          Filter by Persona:
        </label>
        <Listbox value={selectedPersona} onChange={setSelectedPersona}>
          {({ open }) => (
            <div className="relative">
              <ListboxButton className="ag-flex ag-items-center ag-gap-2 ag-border ag-border-gray-300 ag-p-2 ag-rounded">
                {selectedPersona?.persona_name || 'Select Persona'}
                <VscChevronDown
                  className={cn(
                    'ag-transition ag-duration-200',
                    open ? 'ag-rotate-180' : 'ag-rotate-0'
                  )}
                />
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                className={cn(
                  'ag-absolute ag-mt-1 ag-bg-white ag-border ag-shadow-lg ag-rounded ag-z-10 ag-min-w-[200px] ag-overflow-auto text-sm !ag-max-h-96',
                  scrollBarClasses
                )}
              >
                <div className="ag-px-2 ag-py-2">
                  <Input
                    value={searchTerm}
                    placeholder="Search personas..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === ' ') {
                        e.stopPropagation();
                      }
                    }}
                    className="ag-border ag-p-1 ag-rounded ag-border-gray-300 w-full text-sm"
                  />
                </div>
                {filteredPersonas.length > 0 ? (
                  filteredPersonas.map((persona) => (
                    <ListboxOption
                      key={persona.id}
                      value={persona}
                      className="ag-cursor-pointer ag-px-3 ag-py-1.5 hover:ag-bg-gray-100"
                    >
                      {persona.persona_name || `Persona ${persona.id}`}
                    </ListboxOption>
                  ))
                ) : (
                  <div className="ag-px-3 ag-py-2 ag-text-neutral-500">
                    No personas found.
                  </div>
                )}
              </ListboxOptions>
            </div>
          )}
        </Listbox>
      </div>
      <>
        {!selectedPersona ? (
          'Select a persona to view conversations'
        ) : (
          <>
            {conversationsListLoading ? (
              <div
                className={cn(
                  'ag-w-full ag-flex ag-items-center ag-justify-center -ag-pt-24'
                )}
              >
                <Loader size={96} />
              </div>
            ) : (
              <>
                <div className="ag-mb-4 ag-flex ag-justify-between">
                  <span className="ag-font-semibold ag-text-xl">
                    Conversations of {currentPersona?.persona_name}
                  </span>
                </div>
                <DataTable
                  getRowClassName={(row) =>
                    row.status === 'active' ? 'ag-bg-gray-200' : ''
                  }
                  clientFiltering
                  enableRowSelection
                  onChangeSelection={setRowSelection}
                  enablePagination
                  columns={columns}
                  data={conversationsList}
                />
              </>
            )}
          </>
        )}
      </>
    </div>
  );
});
