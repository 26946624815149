import { AxiosResponse } from 'axios';
import {
  Conversation,
  IConversationItem,
  IExternalTranscription,
  IVideoResponse
} from '../model';
import { tavusHttpService } from 'shared/api';
import { userStore } from 'entities/user';
import { ITMessageDaily } from 'shared/model';

const tavusConversationsEndpoint = '/conversation/';

export const conversationApi = {
  createConversation: async ({
    personaId,
    key,
    language,
    preconversationId,
    data
  }: {
    personaId: string;
    key: string | undefined;
    language?: string;
    preconversationId?: string | null;
    data?: {
      [key: string]: string;
    };
  }) => {
    const response = await tavusHttpService.post<Conversation>(
      tavusConversationsEndpoint + `start/${personaId}`,
      data,
      {
        headers: {
          Authorization: userStore.getState().user?.internalKey || key
        },
        params: {
          language,
          preconversation_id: preconversationId
        }
      }
    );

    return response.data;
  },
  downloadVideo: async (conversationId: string) => {
    const { data } = await tavusHttpService.get<IVideoResponse>(
      tavusConversationsEndpoint + `video/${conversationId}`
    );
    return data;
  },
  endConversation: async (
    conversationId: string,
    messages: ITMessageDaily[],
    emotionalTimeline: string
  ) => {
    messages = messages.map((message) => ({
      ...message,
      user_name: message.userName
    }));

    const data: AxiosResponse<any> = await tavusHttpService.post(
      `${tavusConversationsEndpoint}${conversationId}/end`,
      {
        messages,
        emotional_timeline: emotionalTimeline
      }
    );

    return data;
  },
  fetchAll: async (personaId: string) => {
    const { data } = await tavusHttpService.get<IConversationItem[]>(
      tavusConversationsEndpoint + `persona/${personaId}`
    );
    return data;
  },
  getTranscriptions: async (conversationId: string) => {
    const { data } = await tavusHttpService.get<IExternalTranscription[]>(
      tavusConversationsEndpoint + `transcriptions/${conversationId}`
    );
    return data;
  }
};
