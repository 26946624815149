import { ENVIRONMENT, featureFlags } from 'shared/model';
import { create } from 'zustand';

type CreditState = {
  isChatAvailable: boolean;
  premiumVoicesAllowed: boolean;
  productName: string;
};

type CreditActions = {
  updateCreditStore: (key: string, value: any) => void;
  toggleFeatureBlockModal: (open: boolean | typeof features[number]) => void;
  toggleBuyCreditsModal: () => void;
  toggleUpgradeModal: () => void;
  isTAvatarsEnabled: () => boolean;
};

export const features = [
  'express',
  'pro',
  'agents',
  'embed',
  'extraCredits',
  'api',
  'premiumVoices',
  'speech',
  'chatGpt',
  'createAvatar'
] as const;

export type CreditStore = CreditState & CreditActions;

export const creditStore = create<CreditStore>()((set, get) => ({
  toggleUpgradeModal: () => {},
  toggleBuyCreditsModal: () => {},
  toggleFeatureBlockModal: (open) => {},
  isChatAvailable: true,
  premiumVoicesAllowed: false,
  productName: '',
  updateCreditStore: (key, value) => {
    set({ [key]: value });
  },
  isTAvatarsEnabled: () => {
    const productName = get().productName;
    return (
      featureFlags.tAvatars &&
      (['development', 'stating', 'local'].includes(ENVIRONMENT) ||
        (['beta', 'production'].includes(ENVIRONMENT) &&
          ['premium', 'elite'].includes(productName?.toLowerCase())))
    );
  }
}));
